import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { merge, takeRight, isEqual, range } from 'lodash';
import { constants as coreConstants, UX, UX2 } from '@wsb/guac-widget-core';
import Carousel from '@wsb/guac-widget-shared/lib/components/Carousel';
import dataAids from '../../constants/dataAids';
import ReviewBar from '../ReviewBar';
import ReviewCard from '../ReviewCard';
import {
  ArrowType,
  ConnectURLTemplate,
  getApiUrl,
  getRootDomain,
  getConnectStatusUrl,
  ProviderName,
  ProviderIcon,
  ProviderConnectDataEvents,
  Providers,
  ProviderUrls,
  UpgradeURLTemplate,
  STATES,
  MAX_REVIEWS_COUNT
} from '../../constants';
import { SOURCE_TYPES } from '../../../constants';
import { getMappedManualReviews } from '../../utils';

const { MatchMedia } = UX;
const MAX_CARDS_PER_PAGE = 3;
const MAX_CARDS_PER_PAGE_MD = 2;
const MAX_REVIEW_CARDS = MAX_REVIEWS_COUNT;
const MAX_MOBILE_WIDTH = 768;
const MAX_CARD_WIDTH = '290px';
const PREVIEW_MOBILE_CARD_WIDTH = MAX_CARD_WIDTH;
const MOBILE_RENDER_DEVICE = 'MOBILE_RENDER_DEVICE';
const DEFAULT_PROVIDER = Providers.FACEBOOK;
const socialApiProviders = [Providers.GMB, Providers.YELP];
const commerceTierProviders = [Providers.YOTPO];
const COMMERCE_PLANS = ['commerce', 'managedStandardCommerce', 'commerceSap'];

const heightFontScale = {
  small: 430,
  medium: 440,
  large: 460,
  xlarge: 480
};

const staticStyles = {
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  cardsContainer: {
    position: 'relative'
  },
  arrow: {
    'width': 'medium',
    'position': 'absolute',
    'top': '50%',
    'zIndex': 1,
    'cursor': 'pointer',
    'color': 'section',
    ':hover': {
      color: 'rgba(288, 288, 288, 0.6)'
    }
  },
  grid: {
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  },
  cell: {
    flexGrow: 0
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'xlarge'
  },
  pageDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    margin: 'xsmall',
    cursor: 'pointer',
    backgroundColor: 'sectionContrast',
    opacity: 0.6,
    boxShadow: '1px 2px 15px 0 rgba(0,0,0,0.18)'
  },
  selected: {
    opacity: 1
  }
};

class Reviews extends Component {
  static propTypes = {
    viewDevice: PropTypes.string.isRequired,
    renderMode: PropTypes.string.isRequired,
    websiteId: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    isReseller: PropTypes.bool.isRequired,
    env: PropTypes.string.isRequired,
    staticContent: PropTypes.object,
    hasBgImage: PropTypes.bool,
    publishDate: PropTypes.string,
    category: PropTypes.string,
    section: PropTypes.string,
    gmbShowPendingMessage: PropTypes.bool,
    providerType: PropTypes.string,
    fbPageId: PropTypes.string,
    fontScale: PropTypes.string,
    planType: PropTypes.string,
    publishAfterLastUpgrade: PropTypes.bool,
    sourceType: PropTypes.string,
    manualReviews: PropTypes.array,
    market: PropTypes.string
  };

  constructor() {
    super(...arguments);
    this.state = {
      data: {},
      currentPage: 1,
      pages: 0,
      provider: DEFAULT_PROVIDER,
      state: STATES.LOADING,
      isMobile: false,
      cardsPerPage: MAX_CARDS_PER_PAGE,
      dummyData: false,
      planUpgradeRequired: false
    };
  }

  handleMatchMedia = data => {
    const { viewDevice, renderMode } = this.props;
    const publishMode = renderMode === coreConstants.renderModes.PUBLISH;
    const isMobile = publishMode
      ? data.size === 'xs' || data.size === 'sm'
      : /mobile/i.test(viewDevice);
    let cardsPerPage = MAX_CARDS_PER_PAGE;

    if (isMobile) {
      cardsPerPage = 1;
    } else if (data.size === 'md') {
      cardsPerPage = MAX_CARDS_PER_PAGE_MD;
    }

    this.setState({ isMobile, cardsPerPage, size: data.size });
  };

  updateCurrentPage(currentPage) {
    return () => {
      this.setState({ currentPage });
    };
  }

  gotoPage(next = true) {
    return () => {
      const { currentPage, pages } = this.state;
      const addPage = next ? 1 : -1;
      let currPage = currentPage + addPage;
      if (next) {
        if (currPage > pages) {
          currPage = 1;
        }
      } else if (currPage < 1) {
        currPage = pages;
      }

      this.setState({ currentPage: currPage });
    };
  }

  getPageDotStyles(pageNumber) {
    const { currentPage } = this.state;
    let styles = staticStyles.pageDot;

    if (currentPage === pageNumber) {
      styles = merge({}, staticStyles.pageDot, staticStyles.selected);
    }

    return styles;
  }

  fetchData() {
    const { renderMode, providerType, planType, sourceType, manualReviews, staticContent } =
      this.props;
    if (sourceType === SOURCE_TYPES.STATIC) {
      if (manualReviews.length) {
        const data = getMappedManualReviews(manualReviews, staticContent.anonymous);
        this.setState({ data, state: STATES.HAS_REVIEWS });
        this.updatePageCount(data);
      } else {
        this.setState({ state: STATES.NO_REVIEWS });
      }
    } else if (commerceTierProviders.includes(providerType) && !COMMERCE_PLANS.includes(planType)) {
      this.setState({ planUpgradeRequired: true, state: STATES.NOT_SETUP });
    } else {
      this.setState({ planUpgradeRequired: false, state: STATES.LOADING });
      const provider = this.getProvider();
      this.fetchDataForProviders(data => {
        if (data) {
          this.setState({ data, state: STATES.HAS_REVIEWS });
          this.updatePageCount(data);
        } else if (
          renderMode === coreConstants.renderModes.EDIT &&
          socialApiProviders.includes(provider)
        ) {
          this.checkConnectStatusForProvider(provider);
        }
      });
    }
  }

  fetchDataForProviders(cb) {
    const { websiteId, env } = this.props;
    const provider = this.getProvider();
    let url = getApiUrl(env, websiteId, provider);
    if (String(env) !== 'production') {
      const dummyIndex = window.location.search.indexOf('dummy=true');
      if (dummyIndex >= 0) {
        url = `${url}&${window.location.search.substring(dummyIndex)}`;
        this.setState({ dummyData: true });
      } else {
        this.setState({ dummyData: false });
      }
    }

    if (typeof XMLHttpRequest !== 'undefined') {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            // Account not connected and not processed yet
            if (data.updateDate) {
              this.setState({ providerState: STATES.CONNECTED });
              // Account processed but no data yet
              if (data.reviews.length === 0) {
                this.setState({ state: STATES.NO_REVIEWS });
                return void cb(null);
              }
              // Account processed and has data
              this.setState({ state: STATES.HAS_REVIEWS });
              return void cb(data);
            }
            this.setState({
              state: STATES.NOT_SETUP,
              providerState: data.connected ? STATES.CONNECTED : STATES.NOT_CONNECTED
            });
            return void cb(null);
          }
          this.setState({ state: STATES.FAILED });
        }
        return void cb(null);
      };
      xhr.open('GET', url, true);
      xhr.send();
    }
  }

  checkConnectStatusForProvider(provider) {
    const { accountId, env, isReseller } = this.props;
    if (env !== 'production' && this.state.dummyData) {
      this.setState({ providerState: STATES.CONNECTED });
    } else {
      const url = getConnectStatusUrl(env, accountId, isReseller);
      if (typeof XMLHttpRequest !== 'undefined') {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            this.setConnectStatus(data, provider);
          }
        };
        xhr.open('GET', url, true);
        xhr.withCredentials = true;
        xhr.send(null);
      }
    }
  }

  setConnectStatus(data, provider = Providers.GMB) {
    const providerObj = data && data[provider];
    if (providerObj) {
      const submissionId = (Object.keys(providerObj) && Object.keys(providerObj)[0]) || null;
      const apiState =
        (submissionId && providerObj[submissionId] && providerObj[submissionId].status) ||
        STATES.NOT_CONNECTED;
      const providerState = apiState === 'PUBLISHED' ? STATES.CONNECTED : apiState;
      this.setState({ providerState });
    } else {
      this.setState({ providerState: STATES.NOT_CONNECTED });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (
      this.props.providerType !== previousProps.providerType ||
      this.props.sourceType !== previousProps.sourceType ||
      (this.props.sourceType === SOURCE_TYPES.STATIC &&
        !isEqual(previousProps.manualReviews, this.props.manualReviews)) ||
      this.state.cardsPerPage !== previousState.cardsPerPage
    ) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  getProvider() {
    const { sourceType, providerType } = this.props;
    return sourceType === SOURCE_TYPES.STATIC ? Providers.MANUAL : providerType || DEFAULT_PROVIDER;
  }

  updatePageCount(data) {
    const { cardsPerPage } = this.state;
    let pages = 0;
    const totalReviews =
      data.reviews.length > MAX_REVIEW_CARDS ? MAX_REVIEW_CARDS : data.reviews.length;
    if (totalReviews) {
      pages = Math.floor(totalReviews / cardsPerPage);
      pages += (totalReviews / cardsPerPage) % 1 > 0 ? 1 : 0;
    }

    this.setState({ pages });
  }

  getPageLink(data, provider) {
    if (provider === Providers.MANUAL) return null;
    let pageLink = ProviderUrls.base[provider];
    if (data && data.metadata && (data.metadata.pageId || data.metadata.link)) {
      if (provider === Providers.FACEBOOK) {
        pageLink = `${pageLink}/${data.metadata.pageId}/reviews`;
      } else if (provider === Providers.GMB) {
        pageLink = `${pageLink}/maps?cid=${data.metadata.pageId}`;
      } else if (provider === Providers.YELP) {
        pageLink = `${pageLink}/${data.metadata.pageId}`;
      } else if (provider === Providers.YOTPO) {
        pageLink = data.metadata.link || '/';
      }
    }

    return pageLink;
  }

  getCardHeight() {
    const { fontScale } = this.props;
    return heightFontScale[fontScale];
  }

  renderMobileCards(provider, pageLink) {
    const { data, isMobile } = this.state;
    const { renderMode, viewDevice, staticContent, market, sourceType } = this.props;
    if (!data.reviews) {
      return null;
    }

    const desktopMobilePreview =
      renderMode === coreConstants.renderModes.PREVIEW &&
      String(viewDevice) === MOBILE_RENDER_DEVICE &&
      window.innerWidth > MAX_MOBILE_WIDTH;

    const multipleReviews = data.reviews.length > 1;
    const cardHeight = `${this.getCardHeight()}px`;
    return (
      <Carousel
        slideHeight={ cardHeight }
        viewportWidth='100%'
        slideWidth={ desktopMobilePreview ? PREVIEW_MOBILE_CARD_WIDTH : MAX_CARD_WIDTH }
        cellPadding={ 10 }
        initialSlide={ multipleReviews ? 1 : 0 }
        clickToNavigate={ false }
        infinite={ multipleReviews }
        dots={ false }
        arrows={ false }
        style={{
          slide: {
            opacity: '1',
            verticalAlign: 'top'
          },
          selectedSlide: { opacity: '1' }
        }}
      >
        { range(data.reviews.length).map(index => {
          return (
            data.reviews &&
            data.reviews[index] && (
              <ReviewCard
                desktopMobilePreview={ desktopMobilePreview }
                key={ `mobile-review-card-${index}` }
                data={ data.reviews[index] }
                provider={ provider }
                isMobile={ isMobile }
                market={ market }
                staticContent={ staticContent }
                renderMode={ renderMode }
                link={ pageLink }
                sourceType={ sourceType }
                cardHeight={ cardHeight }
              />
            )
          );
        }) }
      </Carousel>
    );
  }

  getCards() {
    const { currentPage, data, isMobile, cardsPerPage } = this.state;
    const { renderMode, staticContent, market, sourceType } = this.props;
    const provider = this.getProvider();
    const maxIndexCount = currentPage * cardsPerPage;
    const pageLink = this.getPageLink(data, provider);

    if (isMobile) {
      return this.renderMobileCards(provider, pageLink);
    }

    return (
      <UX2.Component.Grid style={ staticStyles.grid }>
        { takeRight([maxIndexCount - 3, maxIndexCount - 2, maxIndexCount - 1], cardsPerPage).map(
          item => {
            return (
              data.reviews &&
              data.reviews[item] && (
                <UX2.Component.Grid.Cell key={ item } style={ staticStyles.cell }>
                  <ReviewCard
                    data={ data.reviews[item] }
                    provider={ provider }
                    isMobile={ isMobile }
                    nextPageHandler={ this.gotoPage(true) }
                    previousPageHandler={ this.gotoPage(false) }
                    staticContent={ staticContent }
                    market={ market }
                    renderMode={ renderMode }
                    link={ pageLink }
                    sourceType={ sourceType }
                    cardHeight={ `${this.getCardHeight()}px` }
                  />
                </UX2.Component.Grid.Cell>
              )
            );
          }
        ) }
      </UX2.Component.Grid>
    );
  }

  getNavigationArrow(arrowType) {
    const { hasBgImage, category, section } = this.props;
    let arrowStyle, handler, icon, dataAidValue;
    if (arrowType === ArrowType.RIGHT) {
      arrowStyle = merge({}, staticStyles.arrow, { right: '-35px' });
      handler = this.gotoPage(true);
      icon = 'chevronRight';
      dataAidValue = dataAids.RIGHT_ARROW_RENDERED;
    } else {
      arrowStyle = merge({}, staticStyles.arrow, { left: '-40px' });
      handler = this.gotoPage(false);
      icon = 'chevronLeft';
      dataAidValue = dataAids.LEFT_ARROW_RENDERED;
    }

    return (
      <UX2.Element.Block
        data-aid={ dataAidValue }
        category={ hasBgImage ? 'accent' : category }
        section={ hasBgImage ? 'overlay' : section }
        onClick={ handler }
        style={ arrowStyle }
      >
        <UX2.Element.Icon icon={ icon } />
      </UX2.Element.Block>
    );
  }

  getPaginationControl() {
    const { pages } = this.state;

    return (
      pages > 1 && (
        <UX2.Element.Block style={ staticStyles.paginationContainer }>
          { range(pages).map(index => {
            const pageNumber = index + 1;
            return pages >= pageNumber ? (
              <UX2.Element.Block
                style={ this.getPageDotStyles(pageNumber) }
                onClick={ this.updateCurrentPage(pageNumber) }
              />
            ) : null;
          }) }
        </UX2.Element.Block>
      )
    );
  }

  getConnectProviderHandler(upgradeUrl = false) {
    return () => {
      const { isReseller, env, accountId, websiteId } = this.props;
      const provider = this.getProvider();
      const urlTemplates = upgradeUrl ? UpgradeURLTemplate : ConnectURLTemplate;
      window.location.href = urlTemplates[provider]
        .replace('{rootDomain}', getRootDomain(env, isReseller))
        .replace('{accountId}', accountId)
        .replace('{websiteId}', websiteId)
        .replace('{home}', window.location.href);
    };
  }

  renderMessageInContainer(message, hasBgImage, isMobile, provider) {
    if (!message) return null;
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center'
      },
      messageBlock: {
        paddingHorizontal: isMobile ? 'xsmall' : 'xxlarge',
        paddingVertical: isMobile ? 'large' : 'xlarger',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: 'section'
      },
      icon: {
        width: '45px',
        height: '45px',
        marginBottom: 'medium'
      }
    };
    return (
      <UX2.Element.Container style={ styles.container }>
        <UX2.Element.Text category='accent' section='overlay' style={ styles.messageBlock }>
          <UX2.Element.Block>
            <UX2.Element.Icon icon={ ProviderIcon[provider] } style={ styles.icon } />
            { message }
          </UX2.Element.Block>
        </UX2.Element.Text>
      </UX2.Element.Container>
    );
  }

  displayNoReviewsMessage() {
    const { section, staticContent, hasBgImage } = this.props;
    const textProps = {
      'data-aid': dataAids.REVIEWS_NO_DATA_RENDERED
    };
    return (
      <UX2.Component.PlaceholderMessage
        section={ hasBgImage ? 'overlay' : section === 'default' ? 'alt' : 'default' }
        message={ staticContent.comingSoon }
        textProps={ textProps }
      />
    );
  }

  displayMessages(msg1, msg2, dataAid1, dataAid2) {
    const { isMobile } = this.state;
    const { hasBgImage } = this.props;
    const msg = (
      <UX2.Element.Block>
        <UX2.Element.Text style={{ fontWeight: 'bold' }} children={ msg1 } data-aid={ dataAid1 } />
        <UX2.Element.Text style={{ marginTop: 'medium' }} children={ msg2 } data-aid={ dataAid2 } />
      </UX2.Element.Block>
    );

    return this.renderMessageInContainer(msg, hasBgImage, isMobile, this.getProvider());
  }

  displayPendingMessage() {
    const { staticContent } = this.props;
    return this.displayMessages(
      staticContent.txtPendingValidation,
      staticContent.gmb3Days,
      dataAids.PENDING_VALIDATION_HEADER,
      dataAids.PENDING_VALIDATION_CONTENT
    );
  }

  displayWaitMessage() {
    const { staticContent } = this.props;
    return this.displayMessages(
      staticContent.connectionSuccess,
      staticContent.twentyFourHourLag,
      dataAids.WAITING_FOR_DATA_HEADER,
      dataAids.WAITING_FOR_DATA_CONTENT
    );
  }

  displayConnectOrUpgradeMessage(showUpgrade = false) {
    const { staticContent, renderMode, publishDate, publishAfterLastUpgrade } = this.props;
    const provider = this.getProvider();
    const providersRequiringPublish = socialApiProviders.concat(commerceTierProviders);
    const disableButton =
      providersRequiringPublish.includes(provider) && (!publishDate || publishAfterLastUpgrade);
    const providerName = ProviderName[provider];
    const iconStyles = {
      width: '45px',
      height: '45px',
      marginBottom: 'medium'
    };

    const overlayProps = {
      renderMode,
      beforeContent: (
        <React.Fragment>
          <UX2.Element.Icon icon={ ProviderIcon[provider] } style={ iconStyles } />
          <UX2.Element.Text
            typography='none'
            children={ staticContent[showUpgrade ? 'upgradeMessage' : 'connectMsg'].replace(
              '{provider}',
              providerName
            ) }
            data-aid={ showUpgrade ? dataAids.UPGRADE_TIER_MSG : dataAids.REVIEWS_NOT_CONNECTED_MSG }
          />
          { disableButton && (
            <UX2.Element.Block style={{ marginTop: 'medium' }}>
              { staticContent.publishMessage.replace('{provider}', providerName) }
            </UX2.Element.Block>
          ) }
        </React.Fragment>
      ),
      button: {
        typography: 'none',
        style: disableButton ? { opacity: '0.6', pointerEvents: 'none' } : { opacity: '1' },
        onClick: showUpgrade
          ? this.getConnectProviderHandler(true)
          : this.getConnectProviderHandler(),
        children: staticContent[showUpgrade ? 'upgradeNow' : 'connectBtnText'].replace(
          '{provider}',
          providerName
        ),
        ['data-event']: ProviderConnectDataEvents[provider],
        ['data-aid']: dataAids.REVIEWS_CONNECT_BUTTON
      }
    };

    return <UX2.Component.ZeroStateOverlay { ...overlayProps } />;
  }

  renderReviews() {
    const { data, isMobile } = this.state;
    // hide if not enough to paginate
    const hasReviews = data.reviews && data.reviews.length > this.state.cardsPerPage;

    return (
      <UX2.Element.Block style={ staticStyles.container }>
        <UX2.Element.Block style={ staticStyles.cardsContainer }>
          { !isMobile && hasReviews && this.getNavigationArrow(ArrowType.LEFT) }
          { this.getCards() }
          { !isMobile && hasReviews && this.getNavigationArrow(ArrowType.RIGHT) }
        </UX2.Element.Block>
        { !isMobile && this.getPaginationControl() }
      </UX2.Element.Block>
    );
  }

  showWaitMessage() {
    const { state, providerState } = this.state;
    const { renderMode, gmbShowPendingMessage, fbPageId } = this.props;
    const provider = this.getProvider();
    if (renderMode === coreConstants.renderModes.EDIT && state === STATES.NOT_SETUP) {
      if (provider === Providers.GMB) {
        return providerState === STATES.CONNECTED && !gmbShowPendingMessage;
      } else if (provider === Providers.FACEBOOK) {
        return !!fbPageId;
      } else if (provider === Providers.YELP || provider === Providers.YOTPO) {
        return providerState === STATES.CONNECTED;
      }
      return false;
    }
    return false;
  }

  isProviderNotSetup(provider) {
    const { providerState, planUpgradeRequired } = this.state;
    // show the upgrade dialog
    if (planUpgradeRequired) return false;

    const providersWithConnectStatus = socialApiProviders.concat(commerceTierProviders);
    if (providersWithConnectStatus.includes(provider)) {
      return !(providerState === STATES.CONNECTED);
    } else if (provider === Providers.FACEBOOK) {
      return !this.props.fbPageId;
    } else if (provider === Providers.MANUAL) {
      return false; // No setup needed
    }

    return true;
  }

  providerHasReviews(provider) {
    const { manualReviews } = this.props;
    return provider === Providers.MANUAL
      ? manualReviews && manualReviews.length
      : this.state.state === STATES.HAS_REVIEWS;
  }

  getContainerStyles(noReviews) {
    const { renderMode } = this.props;

    let minHeight = '50px';
    if (renderMode !== coreConstants.renderModes.EDIT) {
      minHeight = noReviews ? 'unset' : '500px';
    }
    const containerStyles = {
      display: 'flex',
      flexDirection: 'column',
      minHeight
    };

    return containerStyles;
  }

  displayReviewBar(containerStyles, provider) {
    const { staticContent, hasBgImage } = this.props;
    const { isMobile, data, state } = this.state;
    const businessName = (data && data.metadata && data.metadata.name) || '';
    return (
      <UX2.Element.Container style={ containerStyles }>
        <ReviewBar
          { ...this.props }
          hasBgImage={ hasBgImage }
          overallRating={ data.overallRating || 0 }
          businessName={ businessName }
          provider={ provider }
          staticContent={ staticContent }
          isMobile={ isMobile }
          pageLink={ this.getPageLink(data, provider) }
          totalReviews={ data.totalReviews || 0 }
          negativeRecommendations={ data.negativeRecommendations || 0 }
          positiveRecommendations={ data.positiveRecommendations || 0 }
        />
        { state === STATES.LOADING ? (
          <UX2.Element.Loader data-aid={ dataAids.REVIEWS_LOADER } style={{ margin: '0 auto' }} />
        ) : (
          this.renderReviews()
        ) }
      </UX2.Element.Container>
    );
  }

  render() {
    const { state, planUpgradeRequired } = this.state;
    const { hasBgImage, gmbShowPendingMessage, renderMode, category, section } = this.props;
    const provider = this.getProvider();
    const hasReviews = this.providerHasReviews(provider);
    const notSetup = this.isProviderNotSetup(provider);
    const pendingValidation =
      gmbShowPendingMessage && !hasReviews && renderMode === coreConstants.renderModes.EDIT;
    const noReviews =
      state === STATES.NO_REVIEWS ||
      (renderMode !== coreConstants.renderModes.EDIT && state === STATES.NOT_SETUP);
    const containerStyles = this.getContainerStyles(noReviews);

    return (
      <UX2.Element.Container
        category={ hasBgImage ? 'accent' : category }
        section={ hasBgImage ? 'overlay' : section }
        style={ containerStyles }
        group='Group'
      >
        <MatchMedia onChange={ this.handleMatchMedia } />
        { hasReviews && this.displayReviewBar(containerStyles, provider) }
        { planUpgradeRequired && this.displayConnectOrUpgradeMessage(true) }
        { this.showWaitMessage() && this.displayWaitMessage() }
        { noReviews && this.displayNoReviewsMessage() }
        { !hasReviews && notSetup && this.displayConnectOrUpgradeMessage() }
        { pendingValidation && this.displayPendingMessage() }
      </UX2.Element.Container>
    );
  }
}

export default Reviews;
